/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import './EditTextModal.css';

function EditTextModal(props) {
  const [title, setTitle] = useState(props?.marker?.text?.title);
  const [text, setText] = useState(props?.marker?.text?.text);
  const [position, setPosition] = useState(props?.marker?.text?.position);

  useEffect(() => {
    if (props?.marker) {
      setTitle(props?.marker?.text?.title);
      setText(props?.marker?.text?.text);
      setPosition(props?.marker?.text?.position);
    }
  }, [props?.marker]);

  return (
    <Modal
      id="edit-text-modal"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          maxWidth: '90%',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
          textAlign: 'center',
          maxHeight: '90%',
          overflow: 'scroll',
        }}
      >
        <h2>Edit text</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <InputLabel id="titleLabel">Text</InputLabel>

          <TextField
            labelId="titleLabel"
            id="outlined-required"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            style={{ marginBottom: 20 }}
          />

          <InputLabel
            id="positionLabel"
            style={{
              marginTop: 30,
            }}
          >
            Position
          </InputLabel>
          <Select labelId="positionLabel" id="demo-simple-select" value={position} onChange={(e) => setPosition(e.target.value)}>
            <MenuItem value={'left'}>Left</MenuItem>
            <MenuItem value={'right'}>Right</MenuItem>
            <MenuItem value={'top'}>Top</MenuItem>
            <MenuItem value={'bottom'}>Bottom</MenuItem>
          </Select>
        </div>

        <Button
          variant="outlined"
          onClick={props.onClose}
          style={{
            margin: 'auto',
            marginTop: 20,
            marginRight: 20,
          }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            props.onSave(props.marker.id, { title, text, position });
            props.onClose();
          }}
          style={{
            margin: 'auto',
            marginTop: 20,
          }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
}

export default EditTextModal;
