/* eslint-disable jsx-a11y/alt-text */
import { useState } from 'react';
import './Montage.css';
import Marker from './marker';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function Montage({ montage }) {
  const { markers, scenes } = montage;
  const [positions, setPositions] = useState([]);
  const [selectedScene, setSelectedScene] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getSceneById = (id) => {
    return scenes.find((i) => i.id === id);
  };

  const nextAnimation = () => {
    if (currentIndex < markers.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
    setSelectedScene(markers[currentIndex].scene);
  };

  const prevAnimation = () => {
    if (currentIndex > markers.length - 1) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(0);
    }

    setSelectedScene(markers[currentIndex].scene);
  };

  const handleClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;

    setPositions([...positions, { x, y, scene: selectedScene }]);
  };

  return (
    <div className="App">
      <header className="App-header">
        {markers[currentIndex] ? (
          <div style={{ position: 'relative' }} onClick={handleClick}>
            <img src={getSceneById(markers[currentIndex] && markers[currentIndex].scene)?.image} style={{ width: '100%', position: 'relative' }} />
            <Marker position={markers[currentIndex]} onClick={() => nextAnimation()} />
            <span className="montage-navigation-buttons">
              <Button className="navigate-button navigate-button-prev" onClick={() => prevAnimation()}>
                <ArrowBackIosIcon />
              </Button>
              <Button className="navigate-button navigate-button-next" onClick={() => nextAnimation()}>
                <ArrowForwardIosIcon />
              </Button>
            </span>
          </div>
        ) : (
          <div style={{ position: 'relative' }} onClick={handleClick}>
            <img src={scenes[0] && scenes[0]?.image} style={{ width: '100%', position: 'relative' }} />
          </div>
        )}
      </header>
    </div>
  );
}

export default Montage;
