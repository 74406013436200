import React from 'react';
import './Footer.css'; // Assuming you want to add some styles

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; {new Date().getFullYear()} QuickDemo. All rights reserved.</p>
        <ul className="footer-links">
          <li>
            <a href="/login">Login</a>
          </li>
          <li>
            <a href="/contact">Register</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
