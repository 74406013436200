import React from 'react';
import Header from './Header';
import { useLoaderData } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import './Montages.css';
import MontageItem from './MontageItem';
import useStripe from './useStripe';
const Montages = () => {
  const navigate = useNavigate();
  const { montages } = useLoaderData();

  React.useEffect(() => {
    if (!montages.length) {
      navigate('/montage/new');
    }
  }, [montages]);

  return (
    <div style={{ height: '100vh', background: 'rgb(242, 242, 242)' }}>
      <Header />

      <div style={styles.container}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            justifyItems: 'center',
          }}
        >
          <h1>Your demos</h1>
          <div
            style={{
              textAlign: 'end',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                if (useStripe.hasActiveSubscription()) {
                  navigate('/montage/new');
                } else {
                  navigate('/pricing');
                }
              }}
            >
              New demo
            </Button>
          </div>
        </div>

        <div className="container">
          {montages.map((montage) => {
            if (!montage.docId) return null;
            return <MontageItem montage={montage} />;
          })}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    backgroundColor: '#f2f2f2',
  },
  heading: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '18px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  button: {
    padding: '10px 20px',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

export default Montages;
