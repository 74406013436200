import React from 'react';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import Stripe from './Stripe';
import './Pricing.css';

const Pricing = () => {
  const navigate = useNavigate();

  return (
    <div style={{ background: 'rgb(242, 242, 242)', height: '100%' }}>
      <Header />
      <div style={styles.container}>
        <h1>Let's get started</h1>

        <section className="pricing">
          <div className="container">
            <div className="pricing-cards">
              <div className="card">
                <h3>Free Plan</h3>
                <p className="price">€0/month</p>
                <ul>
                  <li>Limit of one demo</li>
                  <li>Use demo as iframe</li>
                  <li>Easy to use GIF or iframe</li>
                </ul>
                <button
                  style={{ opacity: '0.5' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate('/montages');
                    console.log('navigate');
                  }}
                  className="btn"
                >
                  Get Started
                </button>
              </div>
              <div className="card">
                <h3>Premium Plan</h3>
                <p className="price">$5/month</p>
                <ul>
                  <li>No limit of demos</li>
                  <li>Use demo as iframe & gif</li>
                  <li>Easy to use GIF or iframe</li>
                </ul>
                <Stripe />
              </div>
            </div>
          </div>
        </section>

        <a
          href="/"
          style={{ fontSize: '.7rem' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate('/montage/');
            console.log('navigate');
          }}
        >
          Continue with free tier
        </a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10%',
    padding: '0 20px',
    backgroundColor: '#f2f2f2',
  },
  heading: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '20px',
    maxWidth: 1000,
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '20px',
    maxWidth: 1000,
    textAlign: 'center',
    fontWeight: 'normal',
  },
  button: {
    padding: '10px 20px',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

export default Pricing;
