import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import EditMontage from './EditMontage';
import Montage from './Montage';
import Pricing from './Pricing';
import NewMontage from './NewMontage';
import Montages from './Montages';
import Dashboard from './Dashboard';
import Login from './Login';
import Register from './Register';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { db } from './firebase';
import { doc, getDoc, getDocs, query, collection, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import ProtectedRouteView from './ProtectedRouteView';

const getMontageById = async (id) => {
  const docRef = doc(db, 'montage', id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), id: id };
  } else {
    // docSnap.data() will be undefined in this case
    console.log('No such document!');
  }
};

const getAllMontages = async () => {
  const q = query(collection(db, 'montage'), where('createdBy', '==', auth?.currentUser.uid));
  const querySnapshot = await getDocs(q);
  const montages = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const ni = {
      ...doc.data(),
      docId: doc.id,
    };
    montages.push(ni);
  });

  return montages;
};

export async function loader({ params }) {
  console.log('App.js: loaderloader params', params);
  return { ...params };
}

export async function getMontageLoader({ params }) {
  // const montage = await getMontage(params.montageId);
  const montage = await getMontageById(params.montageId);
  return { montage };
}

export async function getAllMontagesLoader({ params }) {
  // const montage = await getMontage(params.montageId);
  const montages = await getAllMontages(params.montageId);
  return { montages };
}

const auth = getAuth();
await auth.authStateReady();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/montages',
    loader: getAllMontagesLoader,
    element: <Montages />,
  },
  {
    path: '/pricing',
    element: <Pricing />,
  },
  {
    path: '/montage/new',
    element: (
      <ProtectedRouteView>
        <NewMontage />
      </ProtectedRouteView>
    ),
  },
  {
    path: '/montage/:montageId',
    loader: getMontageLoader,
    element: <Montage />,
  },
  {
    path: '/montage/:montageId/edit',
    loader: getMontageLoader,
    element: (
      <ProtectedRouteView>
        <EditMontage />
      </ProtectedRouteView>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
