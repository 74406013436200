import * as React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Header from './Header';

import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';

const errors = {
  'auth/user-not-found': 'User not found',
  'auth/email-already-in-use': 'Email is already registered',
  general: 'Something went wrong, try again. ',
};

export default function Add() {
  const [error, setError] = React.useState('');
  const navigate = useNavigate();

  const auth = getAuth();

  function signInWithGoogle() {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        console.log('page.js: errorCode', errorCode);
        setError(errors[errorCode || 'general']);
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    createUserWithEmailAndPassword(auth, data.get('email'), data.get('password'))
      .then((userCredential) => {
        const user = userCredential.user;
        console.log('Register.js: user', user);
        navigate('/pricing');
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log('page.js: errorCode', errorCode);
        setError(errors[errorCode] || errors.general);
      });
  };

  return (
    <div style={{ height: '100%' }}>
      <Header />

      <main>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              {error && <Typography sx={{ color: 'red' }}>Error: {error}</Typography>}

              <Button type="submit" fullWidth variant="outlined" sx={{ mt: 3, mb: 1 }}>
                Sign Up
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  type="label"
                  style={{
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    lineHeight: '1.66',
                  }}
                >
                  OR
                </Typography>
              </Box>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }} onClick={signInWithGoogle}>
                Sign Up With Google
              </Button>
              <Grid container>
                <Grid
                  item
                  style={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <a href="/login" variant="body2">
                    {'Already have an account? Sign in'}
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </main>
    </div>
  );
}
