import './NewMontage.css';
// import img1 from './images/1.png';
// import img2 from './images/2.png';
// import img3 from './images/3.png';
import EditOrNewMontage from './EditOrNewMontage';
import { montageRef } from './firebase';
import { addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import Header from './Header';
// const scenesInit = [
//   {
//     id: '1',
//     image: img1,
//   },
//   {
//     id: '2',
//     image: img2,
//   },
//   {
//     id: '3',
//     image: img3,
//   },
// ];

// const animationsInit = [
//   {
//     id: '1',
//     type: 'square',
//     width: 150,
//     height: 150,
//     x: 39.15,
//     y: 38.35583758767117,
//     scene: '1',
//   },
//   {
//     id: '2',
//     type: 'pointer',
//     width: 25,
//     height: 25,
//     x: 50.05,
//     y: 50.99841216378771,
//     scene: '1',
//   },
//   {
//     id: '3',
//     type: 'square',
//     width: 50,
//     height: 50,
//     x: 49.95,
//     y: 55.15565606505317,
//     scene: '2',
//   },
//   {
//     id: '4',
//     type: 'pointer',
//     width: 25,
//     height: 25,
//     x: 54.55,
//     y: 96.57412308136458,
//     scene: '3',
//   },
// ];

function NewMontage() {
  const navigate = useNavigate();

  const auth = getAuth();
  const createMontage = async (scenes, markers, name) => {
    if (!auth?.currentUser?.uid) {
      console.error('No user logged in');
    }
    try {
      const docRef = await addDoc(montageRef, {
        scenes,
        markers,
        name,
        createdAt: new Date(),
        createdBy: auth?.currentUser?.uid,
      });
      navigate(`/montage/${docRef.id}/edit`);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <>
      <Header />
      <EditOrNewMontage /*scenes={scenesInit}*/ saveButtonLabel="Create" title="Create demo" saveButtonOnClick={createMontage} />;
    </>
  );
}

export default NewMontage;
