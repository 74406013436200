import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { getAuth, signOut } from 'firebase/auth';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import LockIcon from '@mui/icons-material/Lock';
import Face6Icon from '@mui/icons-material/Face6';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SchoolIcon from '@mui/icons-material/School';
import HouseIcon from '@mui/icons-material/House';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import useStripe from './useStripe';
const settings = ['Logout'];

function Header() {
  const [billingPortalUrl, setBillingPortalUrl] = React.useState('');
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const auth = getAuth();
  const isLoggedIn = !!auth?.currentUser?.uid;

  React.useEffect(() => {
    if (isLoggedIn) {
      const getPortalUrl = async () => {
        const billingPortalUrl = await useStripe.getBillingPortalUrl();
        setBillingPortalUrl(billingPortalUrl);
      };
      getPortalUrl();
    }
  }, [isLoggedIn]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function logOut() {
    signOut(auth);
    navigate(`/`);
  }

  function billing() {
    useStripe.goToUrl(billingPortalUrl);
  }

  const handleSelectMenuItem = (item) => {
    const actions = { Logout: logOut, Billing: billing };
    actions[item]?.();
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['QuickDemo'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <Link href="/">
              <ListItemButton>
                <ListItemIcon>{index % 2 === 0 ? <HouseIcon /> : <SchoolIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />

      <List>
        {isLoggedIn && (
          <ListItem disablePadding>
            <Link href="/montages">
              <ListItemButton>
                <ListItemIcon>
                  <SchoolIcon />
                </ListItemIcon>
                <ListItemText primary="My demos" />
              </ListItemButton>
            </Link>
          </ListItem>
        )}

        {!isLoggedIn && (
          <>
            <ListItem disablePadding>
              <Link href="/login">
                <ListItemButton>
                  <ListItemIcon>
                    <Face6Icon />
                  </ListItemIcon>
                  <ListItemText primary="Sign in" />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link href="/register">
                <ListItemButton>
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign up" />
                </ListItemButton>
              </Link>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="relative">
      <Container
        style={{
          maxWidth: '100vw',
        }}
      >
        <Toolbar disableGutters>
          <div style={{ cursor: 'pointer' }}>
            <Link href="/">
              <Typography
                variant="h6"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  fontWeight: 600,
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                QuickDemo
              </Typography>
            </Link>
          </div>
          {isLoggedIn && (
            <Box sx={{ flexGrow: 1 }}>
              <Box mr={2} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                <Link href="/montages">
                  <Button sx={{ my: 2, color: 'white', display: 'block' }}>My demos</Button>
                </Link>
              </Box>
            </Box>
          )}

          {!isLoggedIn && (
            <Box sx={{ flexGrow: 1 }}>
              <Box mr={2} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                <Link href="/login">
                  <Button sx={{ my: 2, color: 'white', display: 'block' }}>Sign in</Button>
                </Link>
              </Box>
            </Box>
          )}

          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              mr: 2,

              display: { sm: 'block', md: 'none' },
            }}
            onClick={toggleDrawer('left', true)}
          >
            <MenuIcon />
          </IconButton>

          <Drawer anchor={'left'} open={state.left} onClose={toggleDrawer('left', false)}>
            {list('left')}
          </Drawer>
          {isLoggedIn && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Profile" />
                </IconButton>
              </Tooltip>

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={() => handleSelectMenuItem(setting)}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
                {useStripe.hasActiveSubscription() && (
                  <MenuItem key={'billing'} onClick={() => handleSelectMenuItem('Billing')}>
                    <Typography textAlign="center">Billing</Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
