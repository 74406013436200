import { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import './EditTextModal.css';

function ShareModal(props) {
  const [paddingBottomPercent, setPaddingBottomPercent] = useState(55);

  function calculatePercentage(part, whole) {
    if (whole === 0) {
      return 0; // to avoid division by zero
    }
    return (part / whole) * 100;
  }
  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  getMeta(props.montage.scenes[0]?.image, (_err, img) => {
    setPaddingBottomPercent(calculatePercentage(img?.naturalHeight, img?.naturalWidth));
  });

  const iframeTemplate = `<div style="position: relative; padding-bottom: calc(${paddingBottomPercent}% + 5px); height: 0;"><iframe src="${window.location.origin}/montage/${props.montageId}" title="${props.montage.name || 'Montage'}" allow="clipboard-write" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe> </div><p>Created with <a href="qucikdemo.co">Quickdemo.co</a></p>`;

  return (
    <Modal
      id="edit-text-modal"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          maxWidth: '90%',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
          textAlign: 'center',
          maxHeight: '90%',
          overflow: 'scroll',
        }}
      >
        <h2>Share embedded</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <InputLabel id="titleLabel">Code</InputLabel>

            <Button variant="outlined" onClick={() => navigator.clipboard.writeText(iframeTemplate)}>
              Copy code
            </Button>
          </div>

          <TextField labelId="titleLabel" id="outlined-required" style={{ marginBottom: 20 }} value={iframeTemplate} disabled />
        </div>
        <Button
          variant="outlined"
          onClick={props.onClose}
          style={{
            margin: 'auto',
            marginTop: 20,
            marginRight: 20,
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default ShareModal;
