import React from 'react';
import Header from './Header';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import './Dashboard.css';
const Dashboard = () => {
  const navigate = useNavigate();

  const auth = getAuth();
  const isLoggedIn = !!auth?.currentUser?.uid;

  return (
    <div style={{ background: 'rgb(242, 242, 242)', height: '100%' }}>
      <Header />
      <div style={styles.container}>
        <h1 style={styles.heading}>The ultimate platform for creating interactive demos from your images effortlessly.</h1>
        <p style={styles.paragraph}>
          Forget about using screenshots and images with handwritten text. QuickDemo allows you to effortlessly create beautiful, interactive demos
          for your website, onboarding, or instructional materials.
        </p>
        <span style={{ marginBottom: '2rem' }}>
          <p style={{ margin: '0.5rem' }}>
            <svg
              style={{ marginRight: 10 }}
              xmlns="http://www.w3.org/2000/svg"
              fill="hsl(159 44% 40% / 1)"
              x="0px"
              y="0px"
              width="20"
              height="15"
              viewBox="0 0 50 45"
            >
              <path d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z"></path>
            </svg>
            Easy to use GIF or iframe
          </p>
          <p style={{ margin: '0.5rem' }}>
            <svg
              style={{ marginRight: 10 }}
              xmlns="http://www.w3.org/2000/svg"
              fill="hsl(159 44% 40% / 1)"
              x="0px"
              y="0px"
              width="20"
              height="15"
              viewBox="0 0 50 45"
            >
              <path d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z"></path>
            </svg>
            Increase conversion rate
          </p>
          <p style={{ margin: '0.5rem' }}>
            <svg
              style={{ marginRight: 10 }}
              xmlns="http://www.w3.org/2000/svg"
              fill="hsl(159 44% 40% / 1)"
              x="0px"
              y="0px"
              width="20"
              height="15"
              viewBox="0 0 50 45"
            >
              <path d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z"></path>
            </svg>
            Free tier to get started
          </p>
        </span>
        <button style={styles.button} onClick={() => navigate(isLoggedIn ? '/montage/new' : '/register')}>
          Get Started
        </button>
      </div>

      <div style={{ maxWidth: 1000, margin: 'auto', marginTop: 50, marginBottom: 50 }}>
        <div
          style={{
            display: 'flex',
            width: 'fit-content',
            flexDirection: 'column',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
            transform: 'rotate(-10deg)',
            marginLeft: '1rem',
          }}
        >
          <h4>QuickDemo in action</h4>
          <span style={{ transform: 'rotate(90deg)', width: 'fit-content', height: 'fit-content' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 387.878 216.414">
              <script xmlns="" />
              <g>
                <path d="M336.738,41.644C289.8,25.773,238.017,36.6,196.683,62.528,176.352,11.1,116.874,4.4,74.468,33.11,40.41,54.5,16.937,90.286,7.75,129.167,1.956,150.233-.972,172.037.29,193.893c.742,2.7-1.689,8.956,2.721,8.743,5.481-1.8,1.146-14.32,2.2-19.332-.375-19.025,3.535-37.823,8.69-56.047C23.314,91.752,45.329,59.315,76.75,39.819c40.074-26.57,93.438-20.713,112.07,27.953-42.211,26.2-96.692,120.245-30.7,148.161,72.056,8.665,58.539-102.068,42.217-144.754C240.793,46.055,289.671,37.473,336.738,41.644ZM171.53,206.475c-20.695.819-35.478-22.39-33.082-41.522,4.386-35.37,24.837-68.019,53.939-88.4C203.241,110.076,219.891,197.055,171.53,206.475Z" />
                <path d="M305.035,1.592c-1.647-2.555-6.806-2-7.142,1.4,1.444,5.906,17.421,7.8,22.983,10.349,20.336,5.285,40.074,12.8,56.175,26.684-16.419,15.36-31.8,31.887-43.443,51.233a.009.009,0,0,0,0,.015c11.9-11.24,23.767-22.538,36.346-33.031,6.071-6.279,16.5-11.081,17.919-20.343C370.062,12.108,332.389,11.909,305.035,1.592Z" />
              </g>
            </svg>
          </span>
        </div>
        <div
          style={{
            position: 'relative',
            paddingBottom: 'calc(71.19377162629758% + 5px)',
            height: 0,
          }}
        >
          <iframe
            src={`${window.location.origin}/montage/vB3wi3RgSTVAl7CkQ4WV`}
            title="Unnamed demo"
            allow="clipboard-write"
            frameBorder={0}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen=""
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </div>
      <div>
        <section className="pricing">
          <div className="container">
            <div className="pricing-cards">
              <div className="card">
                <h3>Free Plan</h3>
                <p className="price">€0/month</p>
                <ul>
                  <li>Limit of one demo</li>
                  <li>Use demo as iframe</li>
                  <li>Easy to use GIF or iframe</li>
                </ul>
                <button onClick={() => navigate(isLoggedIn ? '/montage/new' : '/register')} className="btn">
                  Get Started
                </button>
              </div>
              <div className="card">
                <h3>Premium Plan</h3>
                <p className="price">$5/month</p>
                <ul>
                  <li>No limit of demos</li>
                  <li>Use demo as iframe & gif</li>
                  <li>Easy to use GIF or iframe</li>
                </ul>
                <button onClick={() => navigate(isLoggedIn ? '/montage/new' : '/register')} className="btn">
                  Subscribe Now
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10%',
    padding: '0 20px',
    backgroundColor: '#f2f2f2',
  },
  heading: {
    fontSize: '38px',
    fontWeight: 'bold',
    marginBottom: '20px',
    maxWidth: 1000,
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '20px',
    maxWidth: 800,
    textAlign: 'center',
    fontWeight: 'normal',
  },
  button: {
    padding: '10px 20px',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

export default Dashboard;
