/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useRef } from 'react';
import './EditOrNewMontage.css';
import Marker from './marker';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { storage } from './firebase';
import { v4 as uuid } from 'uuid';
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage';
import MontageP from './MontageP';
import ClearIcon from '@mui/icons-material/Clear';
import DragMove from './DragMove';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AdjustIcon from '@mui/icons-material/Adjust';
import ChatIcon from '@mui/icons-material/Chat';
import EditTextModal from './EditTextModal';
import ShareModal from './ShareModal';
import TextField from '@mui/material/TextField';

const actions = { ADD: 'add', MOVE: 'move' };

function NewMontage(props) {
  const [animations, setAnimations] = useState(props.markers || []);
  const [selectedScene, setSelectedScene] = useState();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [scenes, setScenes] = useState(props.scenes || []);
  const [open, setOpen] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [name, setName] = useState(props.name || 'Unnamed demo');
  const [editTextOpen, setEditTextOpen] = useState(false);

  const sceneRef = useRef();

  const updateAnimationsById = (idToUpdate, updatedProperties) => {
    const updatedArray = animations.map((obj) => {
      if (obj.id === idToUpdate) {
        return { ...obj, ...updatedProperties };
      }
      return obj;
    });

    setAnimations(updatedArray);
  };

  const handleOpenShare = () => {
    setOpenShare(true);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!selectedScene && !!scenes.length) {
      setSelectedScene(scenes[0].id);
    }
  }, [scenes, selectedScene]);

  const hasStartedNew = !!animations.length || !!scenes.length;

  const getSceneById = (id) => {
    return scenes.find((i) => i.id === id);
  };

  const handleClick = (event) => {
    if (event.target.id === 'scene-image') {
      setSelectedMarker(null);
    }

    if (isAdding && event.target.id === 'scene-image') {
      const rect = event.target.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / rect.width) * 100;
      const y = ((event.clientY - rect.top) / rect.height) * 100;
      setAnimations([...animations, { x, y, scene: selectedScene, id: uuid(), type: 'pointer', height: 25, width: 25 }]);
    }
  };

  function getAnimationsForScene(sceneId) {
    const animationsForScene = animations.filter((i) => {
      return i.scene === sceneId;
    });
    return animationsForScene;
  }

  const isAdding = selectedAction === actions.ADD;
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newSceneId = scenes.length;
      if (event.target.files[0] === null) {
        console.log('Please select an image');
        return;
      }
      const imageRef = storageRef(storage, `scenes/${uuid()}`);

      uploadBytes(imageRef, event.target.files[0])
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              console.log('URL', url);

              setScenes([
                ...scenes,
                {
                  id: newSceneId + Math.random(),
                  image: url,
                },
              ]);
            })
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const addTextToMarker = (markerId) => {
    updateAnimationsById(markerId, { text: { title: 'Title', text: 'text', position: 'right' } });
  };
  const openEditTextToMarker = () => {
    setEditTextOpen(true);
  };

  const editTextToMarker = (markerId, text) => {
    updateAnimationsById(markerId, { text });
  };

  const setMarkerType = (markerId, type) => {
    const newTypeIsPointer = type === 'pointer';

    if (newTypeIsPointer) {
      updateAnimationsById(markerId, { type, width: 25, height: 25 });
    } else {
      updateAnimationsById(markerId, { type });
    }
  };

  const getMarkerById = (id) => {
    return animations.find((i) => i.id === id);
  };

  const removeMarker = (markerId) => {
    const newMarkers = animations.filter((i) => i.id !== markerId);
    setAnimations(newMarkers);
    setSelectedMarker(null);
  };

  const removeScene = (sceneId) => {
    const newScenes = scenes.filter((i) => i.id !== sceneId);
    const newMarkers = animations.filter((i) => i.scene !== sceneId);

    if (selectedScene === sceneId) {
      setSelectedScene(newScenes?.[0].id);
    }

    setAnimations(newMarkers);
    setScenes(newScenes);
  };

  const toggleSelectedAction = (action) => {
    if (selectedAction === action) {
      setSelectedAction(null);
    } else {
      setSelectedAction(action);
    }
  };

  const getCursor = () => {
    const cursors = {
      [actions.ADD]: 'crosshair',
      [actions.MOVE]: 'move',
    };

    return cursors?.[selectedAction] || 'default';
  };

  if (!hasStartedNew) {
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '0 20px',
        backgroundColor: '#f2f2f2',
      },
      heading: {
        fontSize: '32px',
        fontWeight: 'bold',
        marginBottom: '20px',
      },
      paragraph: {
        fontSize: '18px',
        marginBottom: '20px',
        textAlign: 'center',
      },
      button: {
        padding: '10px 20px',
        fontSize: '18px',
        fontWeight: 'bold',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
      },
    };

    return (
      <div style={styles.container}>
        <h1 style={styles.heading}>Start creating your Demo</h1>
        <p style={styles.paragraph}>Start by uploading an image or screenshot by pressing the button below </p>
        <label htmlFor="file-upload" className="large-file-upload pulse">
          <AddIcon />
        </label>

        <input style={{ display: 'none' }} id="file-upload" type="file" name="myImage" onChange={onImageChange} />
        <div style={{ width: '80%', height: 200, marginTop: 20, padding: 20, background: 'transparent', borderRadius: 5 }}></div>
      </div>
    );
  }

  const handleDragMove3 = (e, id) => {
    const scene = sceneRef.current.getBoundingClientRect();
    if (scene.height && scene.width) {
      const x = ((e.clientX - scene.left) / scene.width) * 100;
      const y = ((e.clientY - scene.top) / scene.height) * 100;
      updateAnimationsById(id, { x, y });
    }
  };

  const updateSize = (id, size) => {
    updateAnimationsById(id, size);
  };
  const fullSelectedMarker = getMarkerById(selectedMarker);

  const sceneBoundingClientRect = sceneRef?.current?.getBoundingClientRect?.();

  return (
    <div className="App">
      <Modal open={open} onClose={handleClose} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            maxWidth: '90%',
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            textAlign: 'center',
            maxHeight: '90%',
            overflow: 'scroll',
          }}
        >
          <MontageP
            montage={{
              scenes,
              markers: animations,
            }}
          />

          <Button
            variant="contained"
            onClick={handleClose}
            style={{
              margin: 'auto',
              marginTop: 20,
            }}
          >
            Close preview
          </Button>
        </Box>
      </Modal>

      <EditTextModal
        open={editTextOpen}
        onClose={() => setEditTextOpen(false)}
        marker={getMarkerById(selectedMarker)}
        onSave={(id, newText) => editTextToMarker(id, newText)}
      />

      {props.montage && <ShareModal open={openShare} onClose={() => handleCloseShare()} montageId={props.id} montage={props.montage} />}

      <header
        className="App-header"
        style={{
          margin: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            justifyItems: 'center',
          }}
        >
          <h1>{props.title}</h1>
          <div
            style={{
              textAlign: 'end',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {props.id && (
              <Button variant="outlined" onClick={handleOpenShare}>
                Share
              </Button>
            )}
            <Button variant="outlined" style={{ marginLeft: 10 }} onClick={handleOpen}>
              Preview
            </Button>
            <Button variant="contained" style={{ marginLeft: 10 }} onClick={() => props.saveButtonOnClick(scenes, animations, name)}>
              {props.saveButtonLabel}
            </Button>
          </div>
        </div>

        <div
          style={{
            marginBottom: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              width: '50%',
              minWidth: '20rem',
            }}
          >
            Title
            <TextField
              id="montage-name-input"
              size="small"
              labelId="titleLabel"
              variant="standard"
              onChange={(e) => setName(e.target.value)}
              value={name}
              style={{ marginBottom: 20, fontSize: 32, width: '100%' }}
            />
          </div>
          <div style={{ display: 'flex' }}>
            {scenes?.map((i) => {
              return (
                <span
                  style={{
                    position: 'relative',
                  }}
                >
                  <div
                    key={i.id}
                    onClick={() => {
                      setSelectedScene(i.id);
                      setSelectedMarker(null);
                    }}
                    style={{
                      height: 100,
                      width: 100,
                      border: selectedScene === i.id ? '2px solid black' : '2px solid transparent',
                      marginRight: 5,
                      backgroundImage: `url(${getSceneById(i.id)?.image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      borderRadius: 5,
                      cursor: 'pointer',
                    }}
                  ></div>
                  <div className="scene-clear-button" onClick={() => removeScene(i.id)}>
                    <ClearIcon style={{ fontSize: '0.7rem' }} />
                  </div>
                </span>
              );
            })}

            <label htmlFor="file-upload" className="large-file-upload">
              <AddIcon />
            </label>

            <input style={{ display: 'none' }} id="file-upload" type="file" name="myImage" onChange={onImageChange} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              position: 'relative',
              cursor: getCursor(),
            }}
            onClick={handleClick}
          >
            <img
              ref={sceneRef}
              id="scene-image"
              draggable="false"
              src={getSceneById(selectedScene)?.image}
              style={{ width: '100%', position: 'relative', userSelect: 'none' }}
            />
            {getAnimationsForScene(selectedScene)?.map((i, index) => {
              const isPointer = i.type === 'pointer';
              return (
                <DragMove onDragMove={(e) => handleDragMove3(e, i.id)}>
                  <Marker
                    sceneBoundingClientRect={sceneBoundingClientRect}
                    sizeUpdate={updateSize}
                    isEdit
                    position={i}
                    onClick={() => setSelectedMarker(i.id)}
                    selected={selectedMarker === i.id}
                  >
                    <div
                      style={{
                        height: 25,
                        width: 25,
                        borderRadius: '50%',
                        position: 'absolute',
                        top: isPointer ? -0.5 : 0,
                        right: isPointer ? -0.5 : 0,
                        backgroundColor: '#1565c0',
                        border: '1px solid white',
                      }}
                    >
                      <p style={{ color: 'white', fontSize: '0.5rem' }}>{index + 1}</p>
                    </div>
                  </Marker>
                </DragMove>
              );
            })}
          </div>
        </div>
      </header>
      <div className="sticky">
        <div className="marker-action-bar">
          <Button className={selectedAction === actions.ADD ? 'selected-action' : 'action-button'} onClick={() => toggleSelectedAction(actions.ADD)}>
            <div className="marker-action-button">
              <span>
                <AddIcon />
              </span>
              <p className="marker-action-button-text">Add marker</p>
            </div>
          </Button>
          {selectedMarker && (
            <Button className={selectedAction === actions.MOVE ? 'selected-action' : 'action-button'} onClick={() => removeMarker(selectedMarker)}>
              <div className="marker-action-button">
                <span>
                  <ClearIcon />
                </span>
                <p className="marker-action-button-text">Remove marker</p>
              </div>
            </Button>
          )}
          {selectedMarker && fullSelectedMarker.type === 'square' && (
            <Button className="action-button" onClick={() => setMarkerType(selectedMarker, 'pointer')}>
              <div className="marker-action-button">
                <span>
                  <AdjustIcon />
                </span>
                <p className="marker-action-button-text">Make pointer</p>
              </div>
            </Button>
          )}
          {selectedMarker && fullSelectedMarker.type === 'pointer' && (
            <Button className="action-button" onClick={() => setMarkerType(selectedMarker, 'square')}>
              <div className="marker-action-button">
                <span>
                  <CropSquareIcon />
                </span>
                <p className="marker-action-button-text">Make square</p>
              </div>
            </Button>
          )}

          {selectedMarker && !fullSelectedMarker.text && (
            <Button className="action-button" onClick={() => addTextToMarker(selectedMarker)}>
              <div className="marker-action-button">
                <span>
                  <ChatIcon />
                </span>
                <p className="marker-action-button-text">Add text</p>
              </div>
            </Button>
          )}
          {selectedMarker && !!fullSelectedMarker.text && (
            <Button className="action-button" onClick={() => openEditTextToMarker(selectedMarker)}>
              <div className="marker-action-button">
                <span>
                  <ChatIcon />
                </span>
                <p className="marker-action-button-text">Edit text</p>
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewMontage;
