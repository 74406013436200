import React from 'react';
import './Montages.css';
import { useNavigate } from 'react-router-dom';

function MontageItem(props) {
  const navigate = useNavigate();

  const convertTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.nanoseconds || !timestamp.seconds) {
      return '';
    }
    const { nanoseconds, seconds } = timestamp;

    // Convert seconds and nanoseconds to milliseconds
    const milliseconds = seconds * 1000 + nanoseconds / 1e6;

    // Create a Date object using the milliseconds
    const date = new Date(milliseconds);

    // Format the date and time
    const formattedDate = date.toLocaleString();
    return formattedDate;
  };

  const date = new Date(convertTimestamp(props.montage?.createdAt)); // Specific date: May 6, 2024
  const options = { month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  return (
    <div className="item" onClick={() => navigate(`/montage/${props.montage?.docId}/edit`)}>
      <div className="montage-list-item ">
        <div className="montage-list-item-title-row">
          <h3>{props.montage?.name || 'Unnamed demo'}</h3>
        </div>
        <img src={props?.montage?.scenes?.[0]?.image} style={{ maxWidth: '100%', width: '100%', position: 'relative' }} />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{formattedDate}</p>
        </div>
      </div>
    </div>
  );
}

export default MontageItem;
