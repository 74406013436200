import './NewMontage.css';
import EditOrNewMontage from './EditOrNewMontage';
import { useLoaderData } from 'react-router-dom';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

function NewMontage() {
  const { montage } = useLoaderData();
  const navigate = useNavigate();

  const saveMontage = (scenes, markers, name) => {
    try {
      updateDoc(doc(db, 'montage', montage.id), { ...montage, scenes, markers, name });
      navigate(`/montage/${montage.id}/edit`);
    } catch (e) {
      console.log('Sparningen misslyckades');
      throw e;
    }
  };

  return (
    <>
      <Header />
      <EditOrNewMontage
        id={montage.id}
        montage={montage}
        name={montage.name}
        scenes={montage.scenes}
        markers={montage.markers}
        saveButtonLabel="Save"
        title="Edit demo"
        saveButtonOnClick={saveMontage}
      />
    </>
  );
}

export default NewMontage;
