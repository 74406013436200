import React from 'react';
import useStripe from './useStripe';

const CheckoutButton = () => {
  const [isLoading, seIsLoading] = React.useState(false);
  const [checkoutSessionUrl, setCheckoutSessionUrl] = React.useState(false);

  React.useEffect(() => {
    seIsLoading(true);

    const getUrl = async () => {
      await useStripe.getCheckoutSessionUrl((url) => {
        console.log('Stripe.jsx: url den här kanske blir rätt?', url);
        setCheckoutSessionUrl(url);
        seIsLoading(false);
      });
    };

    getUrl();
  }, []);

  const addCheckoutSession = async () => {
    seIsLoading(true);
    if (checkoutSessionUrl) {
      useStripe.goToUrl(checkoutSessionUrl);
    } else {
      seIsLoading(false);
    }
  };

  return (
    <>
      <button style={styles.button} onClick={() => addCheckoutSession()}>
        {isLoading ? 'Loading' : 'Checkout'}
      </button>
    </>
  );
};

const styles = {
  button: {
    padding: '10px 20px',
    fontSize: '18px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1em',
  },
};

export default CheckoutButton;
