import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function Marker(props) {
  const position = props.position;
  const { selected } = props;
  const [size, setSize] = React.useState();
  const [anima, setAnim] = React.useState(null);

  const markerRef = React.useRef();
  const textRef = React.useRef();

  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      if (entry.contentRect.width !== 0 || entry.contentRect.height !== 0 || entry.contentRect.width < 25 || entry.contentRect.height < 25) {
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    }
  });

  useEffect(() => {
    if (anima) {
      anima.cancel();
      anima.play();
    } else {
      textRef.current?.getAnimations()?.forEach((anim) => {
        setAnim(anim);
        anim.cancel();
        anim.play();
      });
    }
  }, [props.position]);

  useEffect(() => {
    const scene = props.sceneBoundingClientRect;
    if (!scene?.height && !scene?.width) return;
    const x = (size?.width / scene.width) * 100;
    const y = (size?.height / scene.height) * 100;

    props.sizeUpdate?.(position.id, {
      width: size?.width,
      height: size?.height,

      percentWidth: `${Math.floor(x)}%`,
      percentHeight: `${Math.floor(y)}%`,
    });
  }, [size]);

  useEffect(() => {
    if (markerRef.current) {
      resizeObserver.observe(markerRef.current);
    }
  }, [markerRef]);

  const markersRect = markerRef?.current?.getBoundingClientRect();
  const hasText = !!position?.text;

  const getTextLeft = () => {
    const pos = position?.text?.position;
    const textRect = textRef?.current?.getBoundingClientRect?.();

    if (!textRect) return 0;

    if (pos === 'right') {
      return `calc(${position.x}% + ${markersRect.width / 2}px + 20px)`;
    }
    if (pos === 'top') {
      return `calc(${position.x}% - ${textRect.width / 2}px)`;
    }
    if (pos === 'bottom') {
      return `calc(${position.x}% - ${textRect.width / 2}px)`;
    }
    if (pos === 'left') {
      return `calc(${position.x}% - ${textRect.width}px - ${markersRect.width / 2}px - 10px)`;
    }

    return `calc(${position.x}% - ${textRect.width}px - ${markersRect.width / 2}px - 10px)`;
  };

  const getTexTop = () => {
    const pos = position?.text?.position;
    const textRect = textRef?.current?.getBoundingClientRect?.();

    if (!textRect) return 0;

    if (pos === 'left') {
      return `calc(${position.y}% - ${position.height / 2}px)`;
    }
    if (pos === 'top') {
      return `calc(${position.y}% - ${position.height / 2}px - ${textRect.height}px - 20px)`;
    }
    if (pos === 'bottom') {
      return `calc(${position.y}% + ${position.height / 2}px + ${textRect.height / 2}px + 0px)`;
    }
    if (pos === 'right') {
      return `calc(${position.y}% - ${position.height / 2}px)`;
    }

    return `calc(${position.y}% - ${position.height / 2}px)`;
  };

  const getPointerTextTop = () => {
    const pos = position?.text?.position;
    const textRect = textRef?.current?.getBoundingClientRect?.();
    if (!textRect) return 0;

    if (pos === 'top') {
      return textRect.height;
    }
    if (pos === 'bottom') {
      return -13;
    }

    if (pos === 'left') {
      return textRect?.height / 2 - 7;
    }

    if (pos === 'right') {
      return textRect?.height / 2 - 7;
    }
    return 0;
  };

  const getPointerTextLeft = () => {
    const pos = position?.text?.position;
    const textRect = textRef?.current?.getBoundingClientRect?.();

    if (pos === 'left') {
      return textRect?.width;
    }
    if (pos === 'top') {
      return textRect?.width / 2;
    }
    if (pos === 'bottom') {
      return textRect?.width / 2;
    }
    if (pos === 'right') {
      return -13;
    }

    return 0;
  };

  const getPointerTextRotate = () => {
    const pos = position?.text?.position;

    if (pos === 'left') {
      return 'svg-pointer-left';
    }
    if (pos === 'top') {
      return 'svg-pointer-top';
    }
    if (pos === 'bottom') {
      return 'svg-pointer-bottom';
    }
    if (pos === 'right') {
      return 'svg-pointer-right';
    }

    return 'svg-pointer-left';
  };

  const getHeight = () => {
    if (position.type === 'pointer') {
      return position.height;
    }

    if (!props.isEdit && position.percentHeight) {
      const scene = props.sceneBoundingClientRect;
      if (!scene?.height && !scene?.width) return position.percentHeight;

      return position.percentHeight;
    }
    return position.height;
  };

  const getWidth = () => {
    if (position.type === 'pointer') {
      return position.width;
    }

    if (!props.isEdit && position.percentWidth) {
      return position.percentWidth;
    }
    return position.width;
  };

  const getLeft = () => {
    if (!props.isEdit && position.percentWidth && position.type !== 'pointer') {
      return `calc(${position.x}% - ${getWidth()}/2)`;
    }

    return `calc(${position.x}% - ${position.width / 2}px)`;
  };

  const getTop = () => {
    if (!props.isEdit && position.percentWidth && position.type !== 'pointer') {
      return `calc(${position.y}% - ${getHeight()}/2)`;
    }

    return `calc(${position.y}% - ${position.height / 2}px)`;
  };

  if (!position) {
    return null;
  }
  return (
    <>
      <div
        ref={markerRef}
        onClick={props.onClick}
        className={`marker  ${position.type} ${props.isEdit ? '' : 'marker-animation pulse'} ${selected ? 'marker-selected' : ''}`}
        style={{
          left: getLeft(),
          top: getTop(),
          width: getWidth(),
          height: getHeight(),
          cursor: props.cursor,
        }}
      >
        {props.children}
      </div>

      {hasText && (
        <div
          className={`modal marker-text-modal ${props.isEdit ? '' : 'marker-no-animation'}`}
          ref={textRef}
          style={{
            position: 'absolute',
            left: getTextLeft(),
            top: getTexTop(),
            minWidth: 150,
            maxWidth: 300,
          }}
        >
          <svg
            aria-hidden="true"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            className={`svg-pointer ${getPointerTextRotate()}`}
            style={{
              left: getPointerTextLeft(),
              top: getPointerTextTop(),
            }}
          >
            <path stroke="none" d="M0,0 H14 L7,7 Q7,7 7,7 Z"></path>
            <clipPath id=":r3:">
              <rect x="0" y="0" width="14" height="14"></rect>
            </clipPath>
          </svg>
          <h3 className="modal-header">{position.text.title}</h3>
        </div>
      )}
      {!props.isEdit && (
        <div
          className="marker-text-modal-mobile"
          style={{
            position: 'absolute',
            bottom: 0,
            background: position?.text?.title ? '#1565c0' : 'transparent',
            width: '100%',
            color: 'white',
          }}
        >
          <p style={{ margin: '1rem' }}>{position?.text?.title || '-'}</p>

          <Button onClick={props.onClick} style={{ color: position?.text?.title ? 'white' : '#1565c0', paddingRight: '1rem' }}>
            <ArrowForwardIosIcon />
          </Button>
        </div>
      )}
    </>
  );
}

export default Marker;
