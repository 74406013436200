// Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app';
// import { collection, initializeFirestore, doc } from 'firebase/firestore';
// import { getStorage } from 'firebase/storage';

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: '',
//   authDomain: '',
//   projectId: '',
//   storageBucket: 'fir-9f164.appspot.com',
//   messagingSenderId: '',
//   appId: '',
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// export const db = initializeFirestore(app, {
//   ignoreUndefinedProperties: true,
// });

// // export const projectsRef = collection(db, 'project');
// // export const usersRef = collection(db, 'user');
// // export const projectRef = (projectId) => doc(db, 'project', projectId);

// // export const meRef = (userId) => doc(db, 'user', userId);

// export const storage = getStorage(app, 'gs://fir-9f164.appspot.com');

// ---

import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCsaKZFcY3G8I0cWfbj5XKEu6lpAUvVPdQ',
  authDomain: 'fir-9f164.firebaseapp.com',
  projectId: 'fir-9f164',
  storageBucket: 'fir-9f164.appspot.com',
  messagingSenderId: '94284502847',
  appId: '1:94284502847:web:b06cfe195294f03023a58a',
  measurementId: 'G-9VP01NDSXJ',
};

export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log('firebase.js: analytics', analytics);

export const storage = getStorage();
const auth = getAuth();
export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};
export const signUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const db = getFirestore(app);
export const montageRef = collection(db, 'montage');
export const customersRef = collection(db, 'customers');

export const montageRefId = (id) => collection(db, 'montage', id);
