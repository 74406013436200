import { getAuth } from 'firebase/auth';
import { db } from './firebase';
import { query, collection, where, onSnapshot, addDoc, doc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './firebase';

const hasActiveSubscription = () => {
  const auth = getAuth();

  const currentUser = auth.currentUser;

  const customerDocRef = doc(db, 'customers', currentUser.uid);
  const subscriptionsRef = collection(customerDocRef, 'subscriptions');
  const activeOrTrialingSubscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

  onSnapshot(activeOrTrialingSubscriptionsQuery, (snapshot) => {
    if (snapshot.docs.length > 0) {
      return true;
    } else {
      return false;
    }
  });
};

const addCheckoutSession = async () => {
  const auth = getAuth();

  const currentUser = auth.currentUser;
  if (currentUser) {
    try {
      const checkoutSessionsRef = collection(db, 'customers', currentUser.uid, 'checkout_sessions');
      const docRef = await addDoc(checkoutSessionsRef, {
        // price: 'price_1PP50hIkft9WiTBZXleuDYhq', // prod
        price: 'price_1PQaVbIkft9WiTBZPjIggmeP', // test
        allow_promotion_codes: true,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          alert(`An error occured: ${error.message}`);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  } else {
    console.log('No user is currently signed in.');
  }
};

const getCheckoutSessionUrl = async (cb) => {
  const auth = getAuth();

  const currentUser = auth.currentUser;
  console.log('useStripe.js: getCheckoutSessionUrlgetCheckoutSessionUrl currentUser', currentUser);
  if (currentUser) {
    try {
      const checkoutSessionsRef = collection(db, 'customers', currentUser.uid, 'checkout_sessions');
      const docRef = await addDoc(checkoutSessionsRef, {
        // price: 'price_1PP50hIkft9WiTBZXleuDYhq', // prod
        price: 'price_1PQaVbIkft9WiTBZPjIggmeP', // test
        allow_promotion_codes: true,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      await onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        console.log('useStripe.js: url', url);
        if (error) {
          alert(`An error occured: ${error.message}`);
        }
        if (url) {
          cb(url);
          return url;
        }
      });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  } else {
    console.log('No user is currently signed in.');
  }
};

const goToUrl = async (url) => {
  try {
    if (url) {
      window.location.assign(url);
    }
  } catch (error) {
    console.error(error);
  }
};

const getBillingPortalUrl = async () => {
  const auth = getAuth();

  const user = auth.currentUser;

  let dataWithUrl;
  try {
    const functions = getFunctions(app, 'us-central1');
    const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    // Add a type to the data
    dataWithUrl = data;
    if (dataWithUrl.url) {
      return dataWithUrl.url;
    }
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { addCheckoutSession, hasActiveSubscription, goToUrl, getBillingPortalUrl, getCheckoutSessionUrl };
